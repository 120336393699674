.app {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
}

@media screen and (max-width: 1000px) {
    .app {
        grid-template-rows: 2fr 3fr;
        grid-template-columns: 1fr;
    }
}
