.interfaceWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px auto 60px;
}

@media screen and (max-width: 1000px) {
    .interfaceWrapper {
        grid-template-rows: 60px auto 20px;
    }
}

.titleWrapper {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
}

.title {
    align-self: center;
    font-weight: 500;
    color: #fffafb;
    font-size: 40px;
    margin-left: 10px;
    letter-spacing: 0.1em;
}

@media screen and (max-width: 1000px) {
    .title {
        font-size: 30px;
    }
}

.footer {
    font-size: 20px;
    color: #74746b;
    font-weight: 300;
    align-self: end;
    margin-left: 5px;
    margin-bottom: 5px;
}

@media screen and (max-width: 1000px) {
    .footer {
        font-size: 10px;
    }
}

.headingDivider {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: #74746b;
    font-size: 20px;
    letter-spacing: 0.3em;
}

.headingDivider:before,
.headingDivider:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 35%;
    height: 1px;
    content: '\a0';
    background-color: #74746b;
}
.headingDivider:before {
    margin-left: -40%;
    text-align: right;
}
.headingDivider:after {
    margin-left: 5%;
    text-align: right;
}

.optionsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;
}

.priceFromDistanceCalculator {
    align-self: stretch;
    justify-self: stretch;
}
