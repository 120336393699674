.wrapper {
    padding: 0px 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 1fr;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .wrapper {
        grid-template-rows: 1fr 1fr 1fr;
    }
}

.infoText {
    justify-self: center;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .infoText {
        font-size: 24px !important;
    }
}
