.wrapper {
    padding: 0px 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    align-items: center;
}

.textWrapper {
    justify-self: center;
    text-align: center;
}

.costVisualizer {
    justify-self: center;
}
