.costWrapper {
    justify-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.costText {
    color: #fffafb;
    font-weight: 500;
    font-size: 40px;
}
