@import url(https://fonts.googleapis.com/css?family=Quicksand:300,500);
html {
    margin: 0;
    width: 100vw;
    height: 100vh;
    font-family: 'Quicksand', sans-serif;
}

body {
    margin: 0;
    width: 100vw;
    height: 100vh;
}

#root {
    width: 100vw;
    height: 100vh;
    background-color: #131515;
}

.map_mapContainer__2HxH1 {
    height: 100vh;
}

@media screen and (max-width: 1000px) {
    .map_mapContainer__2HxH1 {
        height: 60vh;
    }
}

.toggle_toggleWrapper__jouuS {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
}

.toggle_selectionText__1ybey {
    color: #fffafb;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.1em;
}

.error_errorWrapper__2_jQM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: #fffafb;
}

.error_error__32KdL {
    padding: 5px 10px;
    font-size: 22px;
    background-color: #e63946;
    border-radius: 2px;
    margin-bottom: 10px;
    letter-spacing: 0.2em;
    font-weight: 500;
}

.error_info__20G3g {
    font-size: 18px;
    font-weight: 300;
}

.costVisualizer_costWrapper__39KvP {
    justify-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.costVisualizer_costText__ygFDi {
    color: #fffafb;
    font-weight: 500;
    font-size: 40px;
}

.infoText_infoText__2KX79 {
    color: #fffafb;
    font-weight: 300;
    font-size: 34px;
    letter-spacing: 0.1em;
}

.priceFromMapCalculator_wrapper__1h-_2 {
    padding: 0px 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 1fr;
    -webkit-align-items: center;
            align-items: center;
}

@media screen and (max-width: 1000px) {
    .priceFromMapCalculator_wrapper__1h-_2 {
        grid-template-rows: 1fr 1fr 1fr;
    }
}

.priceFromMapCalculator_infoText__1u2vF {
    justify-self: center;
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .priceFromMapCalculator_infoText__1u2vF {
        font-size: 24px !important;
    }
}

.distanceInput_infoText__V27fy {
    color: #fffafb;
    font-weight: 300;
    font-size: 34px;
    letter-spacing: 0.1em;
}

.distanceInput_distanceInput__1Zxsu {
    background: transparent;
    outline: none;
    color: #fffafb;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 34px;
    letter-spacing: 0em;
    border: none;
    border-bottom: 1px solid #74746b;
    text-align: right;
    width: 100px;
}

.distanceInput_distanceInput__1Zxsu::-webkit-input-placeholder {
    color: #74746b;
}

.distanceInput_distanceInput__1Zxsu:-ms-input-placeholder {
    color: #74746b;
}

.distanceInput_distanceInput__1Zxsu::placeholder {
    color: #74746b;
}

.distanceInput_distanceInput__1Zxsu::-webkit-outer-spin-button,
.distanceInput_distanceInput__1Zxsu::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.priceFromDistanceCalculator_wrapper__2f8Bt {
    padding: 0px 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    -webkit-align-items: center;
            align-items: center;
}

.priceFromDistanceCalculator_textWrapper__3cLPx {
    justify-self: center;
    text-align: center;
}

.priceFromDistanceCalculator_costVisualizer__1ZsMF {
    justify-self: center;
}

.interface_interfaceWrapper__3n4ST {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px auto 60px;
}

@media screen and (max-width: 1000px) {
    .interface_interfaceWrapper__3n4ST {
        grid-template-rows: 60px auto 20px;
    }
}

.interface_titleWrapper__pkfB0 {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
}

.interface_title__3kkiQ {
    -webkit-align-self: center;
            align-self: center;
    font-weight: 500;
    color: #fffafb;
    font-size: 40px;
    margin-left: 10px;
    letter-spacing: 0.1em;
}

@media screen and (max-width: 1000px) {
    .interface_title__3kkiQ {
        font-size: 30px;
    }
}

.interface_footer__3NV0y {
    font-size: 20px;
    color: #74746b;
    font-weight: 300;
    -webkit-align-self: end;
            align-self: end;
    margin-left: 5px;
    margin-bottom: 5px;
}

@media screen and (max-width: 1000px) {
    .interface_footer__3NV0y {
        font-size: 10px;
    }
}

.interface_headingDivider__1P9v5 {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: #74746b;
    font-size: 20px;
    letter-spacing: 0.3em;
}

.interface_headingDivider__1P9v5:before,
.interface_headingDivider__1P9v5:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 35%;
    height: 1px;
    content: '\a0';
    background-color: #74746b;
}
.interface_headingDivider__1P9v5:before {
    margin-left: -40%;
    text-align: right;
}
.interface_headingDivider__1P9v5:after {
    margin-left: 5%;
    text-align: right;
}

.interface_optionsWrapper__3PLvz {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;
}

.interface_priceFromDistanceCalculator__1qGGF {
    -webkit-align-self: stretch;
            align-self: stretch;
    justify-self: stretch;
}

.app {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
}

@media screen and (max-width: 1000px) {
    .app {
        grid-template-rows: 2fr 3fr;
        grid-template-columns: 1fr;
    }
}

