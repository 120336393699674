@import url('https://fonts.googleapis.com/css?family=Quicksand:300,500');

html {
    margin: 0;
    width: 100vw;
    height: 100vh;
    font-family: 'Quicksand', sans-serif;
}

body {
    margin: 0;
    width: 100vw;
    height: 100vh;
}

#root {
    width: 100vw;
    height: 100vh;
    background-color: #131515;
}
